import '@style/globals.css'
import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import Head from 'next/head'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import '../styles/prism-shades-of-purple.css'
import { getCalApi } from '@calcom/embed-react'
import { GlobalProvider } from '../context/GlobalContext'

const hoves = localFont({
  src: [
    {
      path: '../public/fonts/TT_Hoves_Pro_DemiBold_Italic.woff2',
      weight: '600',
      style: 'italic',
    },
    {
      path: '../public/fonts/TT_Hoves_Pro_DemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../public/fonts/TT_Hoves_Pro_Italic.woff2',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../public/fonts/TT_Hoves_Pro_Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/TT_Hoves_Pro_Medium_Italic.woff2',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../public/fonts/TT_Hoves_Pro_Medium.woff2',
      weight: '500',
      style: 'normal',
    },
  ],
  variable: '--font-hoves',
})

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? '' })
    ;(async function () {
      const cal = await getCalApi('https://meet.monofor.com/embed/embed.js')
      cal('floatingButton', {
        calLink: 'team/demo/book',
        calOrigin: 'https://meet.monofor.com',
        buttonColor: '#042860',
        buttonTextColor: '#ffffff',
        buttonText: 'Book a Demo',
        buttonPosition: 'bottom-left',
      })
      cal('ui', {
        theme: 'light',
        styles: { branding: { brandColor: '#042860' } },
        hideEventTypeDetails: false,
        layout: 'month_view',
      })
    })()
  })

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <main className={`${hoves.variable} main font-sans`}>
        <GlobalProvider>
          <Component {...pageProps} />
        </GlobalProvider>
      </main>
    </>
  )
}
