import { createContext, useContext, useState, useEffect } from 'react'

const GlobalContext = createContext()

export function useGlobalContext() {
  return useContext(GlobalContext)
}

export function GlobalProvider({ children }) {
  const [globalData, setGlobalData] = useState({})

  const loadGlobalData = async () => {
    const result = await fetch('/api/default')
    const json = await result.json()
    setGlobalData(json)
  }

  useEffect(() => {
    loadGlobalData()
  }, [])

  return (
    <GlobalContext.Provider value={{ globalData, loadGlobalData }}>
      {children}
    </GlobalContext.Provider>
  )
}
